import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import imgLogo from "../assets/image/logo-main-black.png";
import imgError from "../assets/image/svg/404.svg";
import axiosConfig from '../axiosConfig';

const Pricing = () => {

  const [pricingData, setPricingData] = useState([]);
  useEffect(() => {
    axiosConfig.get('/subscription-all-plans')
      .then(response => {
        if (response.data.success) {
          console.log(response.data.data);
          setPricingData(response.data.data);

        } else {
          console.error('Failed to fetch  text');
        }
      })
      .catch(error => {
        console.error('Error fetching  text:', error);
      });
  }, []);

  return (
    <>
      <PageWrapper>
        <div className="pt-md pt-17">
          {/* <!-- pricing area function start --> */}
          {/* <!-- pricing section --> */}
          <div className="pricing-area">
            <div className="container pt-12 pt-lg-24 pb-13 pb-lg-25">
              <div className="row justify-content-center">
                <div
                  className="col-xxl-6 col-lg-7 col-md-9"
                  data-aos="fade-in"
                  data-aos-duration="1000"
                  data-aos-delay="500"
                >
                  {/* <!-- section-title start --> */}
                  <div className="section-title text-center mb-12 mb-lg-18 mb-lg-15 pb-lg-15 pb-0">
                    <h2 className="mb-9">
                      Check our amazing plans, choose the best one for you.
                    </h2>
                    <p className="text-default-color font-size-4 px-5 px-md-10 px-lg-15 px-xl-24 px-xxl-22">
                      Complete Design Toolkit – huge collection of elements,
                      rich customization options, flexible layouts.
                    </p>
                  </div>
                  {/* <!-- section-title end --> */}
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-xxl-10 col-xl-11">
                  <div className="row justify-content-center">

                    {pricingData.map((pricing, index) => (
                      <div
                        key={index}
                        className="col-lg-4 col-md-6 col-xs-9"
                        data-aos="fade-right"
                        data-aos-duration="1000"
                        data-aos-delay="500"
                      >
                        {/* Card */}
                        <div className="card border-mercury rounded-8 mb-lg-3 mb-9 px-xl-12 px-lg-8 px-12 pb-12 hover-shadow-hitgray">
                          {/* Card Header */}
                          <div className="card-header bg-transparent border-hit-gray-opacity-5 text-center pt-11 pb-8">
                            <div className="pricing-title text-center">
                              <h5 className="font-weight-semibold font-size-6 text-black-2">
                                {pricing.title}
                              </h5>
                              <h6 className="font-size-4 text-gray font-weight-normal">
                                {pricing.subscription_role === "job-seeker"
                                  ? "For Job Seekers"
                                  : "For Job Providers"}
                              </h6>
                            </div>
                            <h2 className="mt-11 text-dodger">
                              ${pricing.amount}
                              <span className="font-size-4 text-smoke font-weight-normal">
                                /{pricing.duration} Days
                              </span>
                            </h2>
                          </div>
                          {/* Card Body */}
                          <div className="card-body px-0 pt-11 pb-15">
                            <ul className="list-unstyled">
                              {pricing.description.map((item, idx) => (
                                <li
                                  key={idx}
                                  className="mb-6 text-black-2 d-flex font-size-4"
                                >
                                  <i className="fas fa-check font-size-3 text-black-2 mr-3"></i>
                                  {item}
                                </li>
                              ))}
                            </ul>
                          </div>
                          {/* Card Footer */}
                          <div className="card-footer bg-transparent border-0 px-0 py-0">
                            <Link
                              to="/#"
                              className="btn btn-green btn-h-60 text-white rounded-5 btn-block text-uppercase"
                            >
                              Start with {pricing.title}
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}


                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- pricing area function end --> */}
        </div>
      </PageWrapper>
    </>
  );
};
export default Pricing;